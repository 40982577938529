import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';

function Footer() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
 
    function performSignIn() {
      let headers = new Headers();
      let url = "/api/contactInfo";
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:3000');

      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: headers
      })
        .then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }


    performSignIn()

    // Fetch data using axios
    axios.get('/backend/api/contactInfo')

      .then(response => {
        
        setData(response.data); // Set data from API response
        setLoading(false);      // Set loading to false when data is fetched
      })
      .catch(err => {
        setError(err.message);  // Set error message if request fails
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <p>Loading categories...</p>;
  }


  return (
    <div>
      <>
        <footer className="footer-area footer-style-one-wrapper bg-color-footer bg_images tmp-section-gap">
          <div className="container">
            <div className="footer-main footer-style-one">
              <div className="row g-2">
                <div className="col-lg-4 col-md-6">
                  <div className="single-footer-wrapper border-right mr--20">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src="/assets/images/logo/logo1.png"
                          width="150px"
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <p className="description">
                      At Punjab Travels, we’re more than just a service
                      provider. We’re your partner in success.Feel free to
                      adjust the content according to your brand's tone and
                      specific communication channels!
                    </p>
                    <ul className="social-icons solid-social-icons rounded-social-icons">
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="single-footer-wrapper pl-50 pl_md--0 pl_sm--0">
                    <h5 className="ft-title">Quick Link</h5>
                    <ul className="ft-link">
                      <li>
                        <Link to={"/About"}>About Company</Link>
                      </li>
                      <li>
                        <Link to={"/About"}>Meet Our Team</Link>
                      </li>
                      <li>
                        <Link to={"/FindTalent"}>Our Services</Link>
                      </li>
                      <li>
                        <Link to={"/Contact"}>News &amp; Media</Link>
                      </li>
                      <li>
                        <Link to={"/Contact"}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={"/Contact"}>Support</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="single-footer-wrapper pr--15">
                    <h5 className="ft-title">Recent Post</h5>
                    <div className="single-post">
                      <div className="thumbnail">
                        <img
                          src="/assets/images/footer/small-post.jpg"
                          alt="Business_Corporate"
                        />
                      </div>
                      <div className="content">
                        <div className="date">
                          <i className="fa-light fa-calendar-days" />
                          <span>12 Feb, 2024</span>
                        </div>
                        <Link to="/">
                          <h6 className="title">
                            Day tree hide from hath gathered multy
                          </h6>
                        </Link>
                      </div>
                    </div>
                    <div className="single-post">
                      <div className="thumbnail">
                        <img
                          src="/assets/images/footer/small-post-2.jpg"
                          alt="Business_Corporate"
                        />
                      </div>
                      <div className="content">
                        <div className="date">
                          <i className="fa-light fa-calendar-days" />
                          <span>12 Feb, 2022</span>
                        </div>
                        <Link to="/">
                          <h6 className="title">
                            Saving Time Achieving Success in Business
                          </h6>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {data.length > 0 ? (
                data.map(item => (
                
                <div className="col-lg-3 col-md-6">
                  <div className="single-footer-wrapper">
                    <h5 className="ft-title">Official info:</h5>
                    <ul className="ft-link">
                      <li className="ft-location">
                      {item.address}
                      </li>
                      <li>
                        <div className="single-contact">
                          <div className="icon">
                            <i className="fa-solid fa-envelope-open-text" />
                          </div>
                          <div className="content">
                            <span>E-mail:</span>
                            <Link to="mailto:webmaster@example.com">
                            {item.email}
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact">
                          <div className="icon">
                            <i className="fa-light fa-phone" />
                          </div>
                          <div className="content">
                            <span>Phone:</span>
                            <Link to="tel:+351 922 214 445">
                            {item.phone} || {item.secondary_phone}
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
               
                ))
                ) : (
                  <p>No data available.</p>
                )}

              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-area-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-wrapper">
                  <p>
                    © Copyright 2024. All Rights Reserved by{" "}
                    <Link to="#">Punjab Travels & Money Transfer</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Footer;
