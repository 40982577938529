import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';


function BannerSeven() {

const [data, setData] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const [isOpen, setIsOpen] = useState(false);

const openVideo = () => {
    setIsOpen(true);
};

const closeVideo = () => {
    setIsOpen(false);
};

const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
    closeVideo();
    }
};


useEffect(() => {

  function performSignIn() {
    let headers = new Headers();
    let url = "/api/sliders";
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', 'http://localhost:3000');

    fetch(url, {
      mode: 'cors',
      credentials: 'include',
      method: 'GET',
      cache: 'no-cache',
      headers: headers
    })
      .then(response => response.json())
      .then(json => console.log(json))
      .catch(error => console.log('Authorization failed: ' + error.message));
  }


  performSignIn()

  // Fetch data using axios
  axios.get('/backend/api/sliders')

    .then(response => {

      setData(response.data); // Set data from API response
      setLoading(false);      // Set loading to false when data is fetched
    })
    .catch(err => {
      setError(err.message);  // Set error message if request fails
      setLoading(false);
    });
}, []);


if (loading) {
  return <p>loading data || Punjab travels & money transfer...</p>;
}


  return (
    <div>
        <div className="tmp-banner-swiper-one-area construction-2">
            <div className="swiper mySwiper-banner-one-construction">
            <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, EffectFade, Scrollbar, A11y, Autoplay]}
                    className="banner-style-five"
                    speed={500}
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    effect='fade'
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    // pagination={{ clickable: true }}
                    breakpoints={{
                        1200: { slidesPerView: 1 },
                        900: { slidesPerView: 1 },
                        768: { slidesPerView: 1 },
                        580: { slidesPerView: 1 },
                        450: { slidesPerView: 1 },
                        0: { slidesPerView: 1 },
                    }}
                    >

                    {data.length > 0 ? (
                    data.map(item => (

                    <SwiperSlide>
                        <div  className="tmp-banner-area bg_image banner-one-height-control construction-2 tmp-section-gap">
                           
                            <div className='bg-image-section'>
                            <img src={`https://punjabtravels.net/backend/${item.image}`} alt={item.title} />
                            </div>

                            <div className="shape-image-banner-one">
                            <img
                                src="assets/images/banner/P/9.png"
                                alt="banner"
                                className="one"
                            />
                            <img
                              src="assets/images/banner/shape/01.png"
                                alt="banner"
                                className="two"
                            />
                            <img
                                src="assets/images/banner/P/9.png"
                                alt="banner"
                                className="three"
                            />
                            </div>
                            <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                <div className="banner-one-main-wrapper">
                                    <div className="inner">
                                 
                                    <h1 className="title p-0 text-center" style={{marginTop:"-40px" }}>{item.title}</h1>
                                    <p className="disc text-center">
                                      {item.slug}
                                    </p>
                                    <div className="button-area-banner-one">
                                        <Link to={'/'} className="tmp-btn btn-primary">
                                        Get Started Now
                                        </Link>
                                        {/* video icon */}
                                        <div className="vedio-icone" data-tmp-cursor="lg" data-tmp-cursor-text="Play Video">
                                            <Link className="video-play-button play-video" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                openVideo();
                                                }}>
                                                <span />
                                                <p className="text">Play Video</p>
                                            </Link>
                                            
                                            {isOpen && (
                                                <div className="video-overlay open" onClick={closeVideo}>
                                                <iframe
                                                    width="560"
                                                    height="515"
                                                    src={item.video}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                                <Link className="video-overlay-close" onClick={(e) => {
                                                    e.preventDefault();
                                                    closeVideo();
                                                    }}>×</Link>
                                                </div>
                                            )}

                                            {/* Listen for the Escape key to close the video */}
                                            {isOpen && <div onKeyUp={handleKeyUp} tabIndex="0"></div>}
                                        </div>
                                        {/* video icon */}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    ))
                    ) : (
                        <p>No Sliders available.</p>
                    )}

                


           

                 

                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </Swiper>
            </div>
        </div>


    </div>
  )
}

export default BannerSeven