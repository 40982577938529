import React from 'react'
import HeaderInner from '../header/HeaderInner';
import ProjectMesonary from './breadcrumb/ProjectMesonary';
import Footer from '../elements/Footer';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import sal from 'sal.js';
import { Link } from 'react-router-dom';
import HeaderSix from '../header/HeaderSix';
import axios from 'axios';
import { useEffect, useState } from 'react';



function PortfolioSix() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
  
      function performSignIn() {
        let headers = new Headers();
        let url = "/api/JobPost";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
  
  
      performSignIn()
  
      // Fetch data using axios
      axios.get('/backend/api/JobPost')
  
        .then(response => {
  
          setData(response.data); // Set data from API response
          setLoading(false);      // Set loading to false when data is fetched
        })
        .catch(err => {
          setError(err.message);  // Set error message if request fails
          setLoading(false);
        });
    }, []);
  
  
    if (loading) {
      return <p>loading data || Punjab travels & money transfer...</p>;
    }

  return (
    <div>
        {/* <HeaderInner/> */}
        <HeaderSix/>
        <>
        <ProjectMesonary/>
        <>
            {/* recent project areas tart */}
            <div className="recent-project-area tmp-section-gap">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-head">
                        <div className="section-sub-title center-title">
                        <img
                            src="assets/images/services/section-custom-menubar.png "
                            alt="Corporate_service"
                        />
                        <span className="subtitle">Recent Job</span>
                        </div>
                        <h2 className="title split-collab">Our Latest Job Post</h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="recent-project-area-wrapper-swiper">
                            {/* Additional required wrapper */}
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, EffectFade, Scrollbar, A11y, Autoplay]}
                                className="mySwiper-recent-product ptb--30"
                                speed={500}
                                slidesPerView={3}
                                spaceBetween={30}
                                loop={true}
                                autoplay={{
                                    delay: 5500,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                breakpoints={{
                                    1200: { slidesPerView: 3 },
                                    900: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    580: { slidesPerView: 1 },
                                    450: { slidesPerView: 1 },
                                    0: { slidesPerView: 1 },
                                }}
                                >

                            {data.length > 0 ? (
                             data.map(item => (

                                <SwiperSlide>
                                    <div className="tmp-single-portfolio-item">
                                    <Link to={`/ProjectDetails/${item.id}`} className="thumbnail">
                                    <img src={`https://punjabtravels.net/backend/${item.image}`} alt={item.title} />
                                    </Link>
                                    <div className="tmp-inner-content">
                                    <div className="top-item">
                                        <span className="left">{item.category}</span>
                                        <span className="left">{item.closing_at}</span>
                                    </div>
                                    <Link to={`/ProjectDetails/${item.id}`}>
                                        <h2 className="title">
                                         {item.title}
                                        </h2>
                                    </Link>
                                    </div>
                                    </div>
                                </SwiperSlide>

                            ))
                            ) : (
                                <p>No Jobpost available.</p>
                            )}

                              


                                <div
                                    className="swiper-button-next"
                                    data-tmp-cursor="md transparent fw-bold"
                                    data-tmp-cursor-text="Next"/>
                                <div
                                    className="swiper-button-prev"
                                    data-tmp-cursor="md transparent fw-bold"
                                    data-tmp-cursor-text="Prev"
                                />
                            </Swiper>
                            {/* If we need navigation buttons */}
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* recent project areas end */}
            </>

            <Footer/>
        </>
        

    </div>
  )
}

export default PortfolioSix