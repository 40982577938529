import React from 'react'
import HeaderInner from '../header/HeaderInner';
import BreadcrumbSd from '../inner//breadcrumb/BreadcrumbSd';
import Footer from '../elements/Footer';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { useEffect, useState } from 'react';
import HeaderSix from '../header/HeaderSix';




function ServiceDetailsNew() {

    const [data, setData] = useState([]);
    const [JobPostdata, setJobPostdata] = useState([]);
    console.log(JobPostdata)
    console.log(data)

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
   
      function performSignIn() {
        let headers = new Headers();
        let url = "/api/categories";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }

      function performSJobPost() {
        let headers = new Headers();
        let url = "/api/JobPost";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
  
  
      performSignIn() 
      performSJobPost()
  
      // Fetch data for categories
      axios.get('/backend/api/categories').then(response => {       
          setData(response.data); 
          setLoading(false);      
        })
        .catch(err => {
          setError(err.message);  
          setLoading(false);
        });

          
      // Fetch data for jobpost
      axios.get('backend/api/JobPost').then(response => {    
        
        setJobPostdata(response.data);   
        setLoading(false);      
      })
      .catch(err => {
        setError(err.message);  
        setLoading(false);
      });


    }, []);



    if (loading) {
      return <p>Loading data...Punjab Travels</p>;
    }



  return (
    <div className='service-details'>
        <div className="">
            {/* <HeaderInner/> */}
            <HeaderSix/>
            <main>
                <BreadcrumbSd/>
                    {/* tmp service section */}
                    <div className="tmp-service-section tmp-section-gap">
                        <div className="container">
                        <div className="row">

                            <div className="col-lg-8">  
                                <div className='job-content-section'>
                                    <div className="row ">
                                    {/* single service details component */}

                                    {JobPostdata.length > 0 ? (
                                    JobPostdata.map(item => (

                                    <div className="col-lg-12">
                                    <div className="single-service-details-component px-5">

                                        <div className='header-section d-flex justify-content-between '>
                                            <div  className='left p-0 m-0'>
                                                <h3 >{item.title}</h3>
                                                <h5 style={{lineHeight:"1px", fontSize:"16px"}}>{item.category}</h5>
                                            </div>
                                            <div className='right '>
                                            <strong>Apply lastDate: </strong> <span>{item.closing_at}</span>
                                            </div>
                                        </div>
                                    
                                        <p className="disc mb--20">
                                        {item.description}
                                        </p>
                                        <div className="row">
                                        <div className="col-lg-6 col-md-6 ">
                                            {/* chck-area-wrapper */}
                                            <div className="check-service-wrapper ">
                                            <div className="single-check">
                                                <i className="fa-light fa-circle-check" />
                                                <strong>Location: </strong> <p>{item.location}</p>
                                            </div>
                                            <div className="single-check">
                                                <i className="fa-light fa-circle-check" />
                                                <strong>Contact: </strong> <p>{item.Email}</p>
                                            </div>
                                            </div>
                                            {/* chck-area-wrapper end */}
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            {/* chck-area-wrapper */}
                                            <div className="check-service-wrapper">
                                            <div className="single-check">
                                                <i className="fa-light fa-circle-check" />
                                                <strong>Company: </strong><p>{item.company_name}</p>
                                            </div>
                                            <div className="single-check">
                                                <i className="fa-light fa-circle-check" />
                                                <strong>Experience_level: </strong> <p>{item.experience_level}</p>
                                            </div>
                                            </div>
                                            {/* chck-area-wrapper end */}
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                    ))
                                    ) : (
                                    <p>No data available.</p>
                                    )}

                                 
                                    
                                    </div>
                                    </div> 
                            {/* single service details component end */}


                            </div>
                            <div className="col-lg-4 pl--50 pl_md--15 pl_sm--10 mt_md--50 mt_sm--50">
                            <div className="side-bar-details-page">
                                {/* single bar */}
                                <div className="signle-side-bar search-area">
                                <div className="body">
                                    <div className="search-area">
                                    <input type="text" placeholder="Entry Key" />
                                    <i className="fa-solid fa-magnifying-glass" />
                                    </div>
                                </div>
                                </div>
                                {/* single bar end */}
                                {/* single bar */}
                                <div className="signle-side-bar category-area">
                                <div className="header">
                                    <h3 className="title">Category List</h3>
                                </div>
                                <div className="body">

                                    {data.length > 0 ? (
                                    data.map(item => (

                                    
                                    <a href="#" className="single-category">
                                    <p>{item.name}</p>
                                    <i className="fa-light fa-arrow-right" />
                                    </a>
                                
                                    ))
                                    ) : (
                                    <p>No data available.</p>
                                    )}

                                </div>
                                </div>
                                {/* single bar end */}
                                {/* single bar */}
                                <div className="signle-side-bar call-to-action">
                                <div className="easy-call-to-action">
                                    <div className="icon-center-call">
                                    <i className="fa-light fa-phone" />
                                    </div>
                                    <h3 className="title">Need any help?</h3>
                                    <p className="disc">
                                    We are here to help our customer any time. You can call on
                                    24/7 To Answer Your Question.
                                    </p>
                                    <a href="#">+351 922 214 445</a>
                                </div>
                                </div>
                                {/* single bar end */}
                         
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* tmp service section end */}
                <Footer/>
            </main>
        </div>
    </div>
  )
}

export default ServiceDetailsNew