import HeaderInner from "../header/HeaderInner";
import BreadcrumbBlog from "../inner/breadcrumb/BreadcrumbBlog";
import Footer from "../elements/Footer";
import { Link } from "react-router-dom";
import HeaderSix from "../header/HeaderSix";
import React, { useEffect, useState } from "react";
import axios from "axios";
import WOW from "wowjs";
import BreadcrumbStaffRequisition from "./breadcrumb/BreadcrumbStaffRequisition";
import StaffRequisitionForm from "./StaffRequisitionForm";

function StaffRequisition() {
  return (
    <div classNameName="service-details">
      <div classNameName="">
        {/* <HeaderInner/> */}
        <HeaderSix />
        <main>
          {/* <BreadcrumbBlog/> */}
          <BreadcrumbStaffRequisition />
          {/* latest blog area start */}

          <div className="altest-blog-area tmp-section-gap">
            <div className="container ">
              <div className="row" >
                <div className="col-lg-12">
                  <div className="section-head">
                    <div className="section-sub-title" style={{textAlign:"left" , padding:"0 20px"}}>
                      <img
                        src="assets/images/services/service-icon-1.png "
                        alt="Business_Consulting_services"
                        height="30px"
                        width="30px"
                      />
                      <span> Staff Requisition Form</span>
                    </div>
                     <h2 style={{textAlign:"left" , padding:"0 20px"}} classNameName="title split-collab">Looking for Workers?</h2>
                    <p style={{textAlign:"left" , padding:"0 20px"}}>
                      Please tell us how we can help you. Only fields marked
                      with * are required. Your information is protected by our
                      privacy policy. Please take into consideration the following before
                      completing the Staff Requisition Form below: Work Supply –
                      Trabalho Temporário Lda does not search for, provide or
                      accept any responsibilities with regard to accommodation
                      and transportation to and from the work location/s for
                      posted workers. However, this does not necessarily mean
                      that accommodation and transportation must be provided
                      free of charge by the client.
                    </p>
                  </div>
                </div>

              
           
             <StaffRequisitionForm/>

           

              </div>
            </div>
          </div>
          {/* latest blog area end */}
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default StaffRequisition;
