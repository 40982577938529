import React from 'react'
import HeaderFive from '../header/HeaderFive';
import BannerSeven from '../elements/BannerSeven';
import ServiceSix from '../elements/ServiceSix';
import AboutFive from '../elements/AboutFive';
import ServiceFIve from '../elements/ServiceFIve';
import FunFactsTwo from '../elements/FunFactsTwo';
import WorkingProcessTwo from '../elements/WorkingProcessTwo';
import PortfolioFIve from '../elements/PortfolioFIve';
import FaqThree from '../elements/FaqThree';
import TeamFour from '../elements/TeamFour';
import LargeVideoTwo from '../elements/LargeVideoTwo';
import TestimonialsBrand from '../elements/TestimonialsBrand';
import Contact from '../elements/Contact';
import CtaTwo from '../elements/CtaTwo';
import BlogSix from '../elements/BlogSix';
import Footer from '../elements/Footer';
import Portfolio from '../elements/Portfolio';
import HeaderFour from '../header/HeaderFour';
import HeaderSix from '../header/HeaderSix';
import HeaderThree from '../header/HeaderThree';
import BannerEight from '../elements/BannerEight';
import BannerFive from '../elements/BannerFive';
import BannerFour from '../elements/BannerFour';
import BannerOne from '../elements/BannerOne';
import BannerThree from '../elements/BannerThree';
import BannerTwo from '../elements/BannerTwo';
import BannerSlider from '../elements/BannerSlider';
import ServiceThree from '../elements/ServiceThree';
import ServiceTwo from '../elements/ServiceTwo';
import ServiceOne from '../elements/ServiceOne';
import PortfolioSix from '../elements/PortfolioSix';
import ServiceNew from '../elements/ServiceNew';
import ServiceNewStaff from '../elements/ServiceNewStaff';
import CareerConsultation from '../elements/CareerConsultation';
import IconSection from '../elements/IconSection';



function HomeSeven() {
    return (
        <div>
            <div className="index-seven">
                <HeaderSix/>
                {/* <HeaderFive/> */}
                <main>
                  
                    <BannerSeven/>
                    {/* <BannerSlider/> */}
                    {/* <BannerThree/> */}
                    {/* <BannerFour/>   */}
                    {/* <BannerOne/> */}
                    <br/>
                    {/* <ServiceFIve/> */}
                    {/* <ServiceThree/> */}
                    <IconSection/>
                    <ServiceNew/>
                    <br/>
                    <ServiceNewStaff/>
                    <br/>
                    <CareerConsultation/>
                    <br/>
                    <Portfolio/>
                    {/* <PortfolioFIve/> */}
                    <Contact/>
                    <br/>
                    <Footer/>
                </main>
            </div>
        </div>
    )
}

export default HomeSeven