import React from 'react';

const IconSection = () => {
    return (
        <>

        <div className="icon-section">
    
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f icon"></i>
            <span className="tooltip ">Facebook</span>
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter icon"></i>
            <span className="tooltip">Twitter</span>
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram icon"></i>
            <span className="tooltip">Instagram</span>
        </a>
        </div>

            
        </>
    );
};

export default IconSection;