import React from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

import axios from 'axios';
import { useEffect, useState } from 'react';

function ServiceNew() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
  
      function performSignIn() {
        let headers = new Headers();
        let url = "/api/categories";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
  
  
      performSignIn()
  
      // Fetch data using axios
      axios.get('/backend/api/categories')
  
        .then(response => {
  
          setData(response.data); // Set data from API response
          setLoading(false);      // Set loading to false when data is fetched
        })
        .catch(err => {
          setError(err.message);  // Set error message if request fails
          setLoading(false);
        });
    }, []);
  
  
    if (loading) {
      return <p>loading data || Punjab travels & money transfer...</p>;
    }
  

  return (
    <div>
        {/* service area start */}
        <div className="tmp-service-area tmp-section-gapTop">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-head">
                    <div className="section-sub-title center-title">
                        <img
                            src="assets/images/services/service-icon-1.png "
                            alt="Business_Consulting_services"
                            height="30px" width="30px"
                        />
                        <span>OUR SERVICES</span>
                        </div>
                        <h2 className="title split-collab">Our Provided Services</h2>
                    </div>
                    </div>
                </div>
                <div className="row g-5">
                    <div className='col-lg-12'>
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                            className="swiper-container-three-1"
                            speed={500}
                            slidesPerView={4}
                            spaceBetween={30}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                1200: { slidesPerView: 4 },
                                900: { slidesPerView: 3 },
                                768: { slidesPerView: 2 },
                                580: { slidesPerView: 2 },
                                450: { slidesPerView: 1 },
                                0: { slidesPerView: 1 },
                            }}
                        >

                            {data.length > 0 ? (
                             data.map(item => (

                            <SwiperSlide>
                                {/* single -service three */}
                                <div className="service-single-three">
                                    <Link to={'/JobDetails'}>
                                    <div className="icon" style={{ color: '#0069D9', fontSize: '40px', textAlign:"center" }}>
                                    <i className={`fa ${item.icon}`} aria-hidden="true"></i>          
                                    </div>
                                    <h5 className="title">{item.name}</h5>
                                    </Link>
                                    <p className="disc">
                                     Our company provides specific services within the building construction industry & others sector,
                                      you might want to tailor job titles based on those particular categories.
                                    </p>
                                    
                                    <Link className="link-over" to={'/JobDetails'} />
                                </div>
                                {/* single -service end */}
                            </SwiperSlide>

                            ))
                            ) : (
                                <p>No Services available.</p>
                            )}
                    

            
                        </Swiper>
                    </div>

                </div>
            </div>
        </div>
       
        {/* service area end */}

    </div>
  )
}

export default ServiceNew