import { Link } from "react-router-dom";

const CareerConsultation = () => {
  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="career-section">
        <div className="row">
          <div className="col-lg-6 m-0 left-section">
            <span>Need a Job, Having a Dream to Settle up</span>
            <h2>Career Consultation</h2>
            <p>
              If you aspire to pursue a career abroad, Grow Strive is the ideal
              choice for you to pursue career opportunities with us, as we
              possess a substantial number of openings in diverse fields. We
              hire manpower for Hotels, Manufacturing, Construction, Cleaning,
              Electricians, Plumbers, Gardeners, House keepers etc. So, Grow
              Strive can Help you to get settled at your dreamland.
            </p>
            <strong>Click apply and upload the important information.</strong>
            <button className="btn"><Link to="/StaffRequisition">Apply To get hire!</Link> </button>
          </div>
            <div className="col-lg-6 right-section">
                <div className="img-section"> <img src="/assets/images/banner/abcd.png"/></div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default CareerConsultation;
