
import './App.css';
import Home from './home/Home';

import HomeTwo from './home/HomeTwo';
import HomeThree from './home/HomeThree';
import HomeFour from './home/HomeFour';
import HomeFive from './home/HomeFive';
import HomeSix from './home/HomeSix';
import HomeSeven from './home/HomeSeven';
import HomeEight from './home/HomeEight';

import About from './inner/About';
import Text from './inner/GoogleTranslate';

import Service from './inner/Service';
import ServiceDetails from './inner/ServiceDetails';
import Blog from './inner/Blog';
import BlogColOne from './inner/BlogColOne';
import BlogColTwo from './inner/BlogColTwo';
import BlogDetails from './inner/BlogDetails';
import BlogDetailsTwo from './inner/BlogDetailsTwo';
import Appoinment from './inner/Appoinment';
import ProjectMesonary from './inner/ProjectMesonary';
import ProjectMesonaryThree from './inner/ProjectMesonaryThree';
import Project from './inner/Project';
import ProjectSlider from './inner/ProjectSlider';
import ProjectDetails from './inner/ProjectDetails';
import ProjectDetailsTwo from './inner/ProjectDetailsTwo';
import TeamOne from './inner/TeamOne';
import TeamTwo from './inner/TeamTwo';
import TeamDetails from './inner/TeamDetails';
import Pricing from './inner/Pricing';
import FaqOne from './inner/FaqOne';
import Error from './inner/Error';
import Contact from './inner/Contact';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LoadTop from './components/LoadTop';
import BackTop from './components/BackTop';
import CurrentLink from './components/CurrentLink';
import Preloader from './components/Preloader';
import ServiceDetailsNew from './inner/ServiceDetailsNew';
import GoogleTranslate from './inner/GoogleTranslate';
import BreadcrumbStaffRequisition from './inner/breadcrumb/BreadcrumbStaffRequisition';
import StaffRequisition from './inner/StaffRequisition';
import IconSection from './elements/IconSection';

// import * as express from 'express';
// import express from "express";

function App() {

  // const express = require('express');
  // const cors = require('cors');
  // const app = express();

  // // Allow requests from specific origins
  // app.use(cors({
  //   origin: 'http://localhost:3000'  // Allow requests from React app running on port 3000
  // }));

  // // OR to allow requests from any origin, use this:
  // // app.use(cors());



  // app.listen(8000, () => {
  //   console.log('Server running on port 8000');
  // });











  return (
    
    <div className="App">

      {/* <GoogleTranslate/> */}

      <Router>

        <LoadTop />
        <CurrentLink />
        <BackTop />
        <IconSection/>

        {/* <Preloader /> */}

        <Routes>
          <Route path="/" element={<HomeSeven />}> </Route>
          <Route path="/FindTalent" element={<Service />}> </Route>
          <Route path="/FindJob" element={<ProjectSlider />}> </Route>
          <Route path="/OurThinking" element={<Blog />}> </Route>
          <Route path="/JobDetails" element={ <ServiceDetailsNew/> }> </Route>
          <Route path="/StaffRequisition" element={ <StaffRequisition/> }> </Route>

          {/* <Route path="/JobDetails" element={<ServiceDetails />}> </Route> */}
          <Route path="/Contact" element={<Contact />}> </Route>
          <Route path="/ProjectDetails/:id" element={<ProjectDetails />}> </Route>
          <Route path="/About" element={<About />}> </Route>
          {/* <Route path="/text" element={<Text/>}> </Route> */}

          <Route path="/BlogColOne" element={<BlogColOne />}> </Route>
          <Route path="/cd back" element={<BlogColTwo />}> </Route>
          <Route path="/BlogDetails" element={<BlogDetails />}> </Route>
          <Route path="/BlogDetailsTwo" element={<BlogDetailsTwo />}> </Route>

          {/* <Route path="/home" element={<Home />}> </Route>
          <Route path="/home-two" element={<HomeTwo />}> </Route>
          <Route path="/home-three" element={<HomeThree />}> </Route>
          <Route path="/home-four" element={<HomeFour />}> </Route>
          <Route path="/home-five" element={<HomeFive />}> </Route>
          <Route path="/home-six" element={<HomeSix />}> </Route>
          <Route path="/home-seven" element={<HomeSeven />}> </Route>
          <Route path="/home-eight" element={<HomeEight />}> </Route>
          <Route path="/About" element={<About />}> </Route>
          <Route path="/Service" element={<Service />}> </Route>
          <Route path="/ServiceDetails" element={<ServiceDetails />}> </Route>
          <Route path="/Blog" element={<Blog />}> </Route>
          <Route path="/BlogColOne" element={<BlogColOne />}> </Route>
          <Route path="/BlogColTwo" element={<BlogColTwo />}> </Route>
          <Route path="/BlogDetails" element={<BlogDetails />}> </Route>
          <Route path="/BlogDetailsTwo" element={<BlogDetailsTwo />}> </Route>
          <Route path="/Appoinment" element={<Appoinment />}> </Route>
          <Route path="/Project" element={<Project />}> </Route>
          <Route path="/ProjectMesonary" element={<ProjectMesonary />}> </Route>
          <Route path="/ProjectMesonaryThree" element={<ProjectMesonaryThree />}> </Route>
          <Route path="/ProjectSlider" element={<ProjectSlider />}> </Route>
          <Route path="/ProjectDetails" element={<ProjectDetails />}> </Route>
          <Route path="/ProjectDetailsTwo" element={<ProjectDetailsTwo />}> </Route>
          <Route path="/TeamOne" element={<TeamOne />}> </Route>
          <Route path="/TeamTwo" element={<TeamTwo />}> </Route>
          <Route path="/TeamDetails" element={<TeamDetails />}> </Route>
          <Route path="/Pricing" element={<Pricing />}> </Route>
          <Route path="/FaqOne" element={<FaqOne />}> </Route>
          <Route path="/Error" element={<Error />}> </Route>   */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;


