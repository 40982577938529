import React from 'react'
import HeaderInner from '../header/HeaderInner';
import Breadcrumb from '../inner/Breadcrumb';
import AboutOne from '../elements/AboutOne';
import WorkingProcessThree from '../elements/WorkingProcessThree';
import Team from '../elements/Team';
import TestimonialsBrand from '../elements/TestimonialsBrand';
import Footer from '../elements/Footer';
import HeaderSix from '../header/HeaderSix';

function about() {
    return(

        <div className="index-one">

            {/* <HeaderInner/> */}
            <HeaderSix/>
        <main>
            <Breadcrumb/>
            <AboutOne/>
            {/* <WorkingProcessThree/> */}
            <Team/>
            {/* <TestimonialsBrand/> */}
            <Footer/>


        </main>


        </div>
        
    )
}

export default about