import HeaderInner from '../header/HeaderInner';
import BreadcrumbBlog from '../inner/breadcrumb/BreadcrumbBlog';
import Footer from '../elements/Footer';
import { Link } from 'react-router-dom';
import HeaderSix from '../header/HeaderSix';
import React, { useEffect,useState }  from 'react';
import axios from 'axios';
import WOW from "wowjs";



function ServiceDetails() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    useEffect(() => {

        function performSignIn() {
            let headers = new Headers();
            let url = "/api/blogPost";
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin', 'http://localhost:3000');
      
            fetch(url, {
              mode: 'cors',
              credentials: 'include',
              method: 'GET',
              cache: 'no-cache',
              headers: headers
            })
              .then(response => response.json())
              .then(json => console.log(json))
              .catch(error => console.log('Authorization failed: ' + error.message));
          }

        performSignIn()

        // Fetch data using axios
        axios.get('/backend/api/blogPost')

        .then(response => {
        
        setData(response.data); // Set data from API response
        setLoading(false);      // Set loading to false when data is fetched
        })
        .catch(err => {
        setError(err.message);  // Set error message if request fails
        setLoading(false);
        });

        // This disables continuous scanning for new elements
        const wow = new WOW.WOW({
          live: false, 
        });
        wow.init();

      }, []);

      if (loading) {
        return <p>loading data || Punjab travels & money transfer...</p>;
      }


  return (
    <div className='service-details'>
        <div className="">
            {/* <HeaderInner/> */}
            <HeaderSix/>
            <main>
                <BreadcrumbBlog/>
                    {/* latest blog area start */}

                    <div className="altest-blog-area tmp-section-gap">
                        <div className="container">
                        <div className="row g-5">

                           {data.length > 0 ? (
                             data.map(item => (

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img src={`https://punjabtravels.net/backend/${item.image}`} alt={item.name}
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">{item.created_at}</span>
                                    <span className="designation">{item.category}</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        {item.title}
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            ))
                            ) : (
                            <p>No data available.</p>
                            )}

                            {/* <div className="tmp-pagination-area-next-pev mt--50">
                            <button>
                                <i className="fa-regular fa-chevron-left" />
                            </button>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                            <button>
                                <i className="fa-sharp fa-regular fa-chevron-right" />
                            </button>
                            </div> */}
                        </div>
                        </div>
                    </div>
                    {/* latest blog area end */}
                <Footer/>
            </main>
        </div>
    </div>
  )
}

export default ServiceDetails