import emailjs from "emailjs-com";
import HeaderInner from "../header/HeaderInner";
import Contact from "../inner/breadcrumb/Contact";
import Footer from "../elements/Footer";
import HeaderFive from "../header/HeaderFive";
import HeaderFour from "../header/HeaderFour";
import HeaderTwo from "../header/HeaderTwo";
import HeaderOne from "../header/HeaderOne";
import HeaderSix from "../header/HeaderSix";
import axios from 'axios';
import { useEffect, useState } from 'react';

function ContactPage() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    subject: '',
  });

  const [message, setMessage] = useState(''); // State for messages

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value);
    setFormData({
      ...formData,
      [name]: value, // Use name as the key to update the corresponding field
    });
  };
  
  function performSignIn() {
    let headers = new Headers();
    let url = "/backend/api/userFeadback/store";
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', 'http://localhost:3000');

    fetch(url, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      cache: 'no-cache',
      headers: headers
    })
      .then(response => response.json())
      .then(json => console.log(json))
      .catch(error => console.log('Authorization failed: ' + error.message));
  }
   performSignIn()

    // Handle form submission
    const handleSubmit = async (e) => {

      e.preventDefault();
      setMessage(''); // Clear previous message
  
      try {
        const response = await axios.post('https://punjabtravels.net/backend/api/userFeadback/store', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Show success message
        setMessage(response.data.message);
      } catch (error) {
        // Handle error and show error message
        if (error.response && error.response.data) {
          setMessage(error.response.data.message || 'An error occurred');
        } else {
          setMessage('An error occurred');
        }
      }
    };


  console.log(formData);


  useEffect(() => {
 
    function performSignIn() {
      let headers = new Headers();
      let url = "/api/contactInfo";
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:3000');

      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: headers
      })
        .then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }


    performSignIn()

    // Fetch data using axios
    axios.get('/backend/api/contactInfo')

      .then(response => {
        
        setData(response.data); // Set data from API response
        setLoading(false);      // Set loading to false when data is fetched
      })
      .catch(err => {
        setError(err.message);  // Set error message if request fails
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <p>Loading data...</p>;
  }


  return (
    <div className="service-details contact-page">
      <div className="">
        {/* <HeaderInner/> */}
        <HeaderSix />
        {/* <HeaderFive/> */}
        {/* <HeaderFour/> */}
        {/* <HeaderTwo/> */}
        {/* <HeaderThree/> */}
        {/* <HeaderOne/> */}
        <main>
          <Contact />
          {/* tmp contact area */}
          <div className="contact-area tmp-section-gap">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-5">

               
                      
                  <div className="contact-inner">
                    <div className="section-head section-head-one-side text-align-left">
                      <span className="title">Contact Us</span>
                      <p className="description">
                        Don’t hesitate to get in touch—your next big success
                        could start with a simple conversation. Fill out the
                        contact form below, and let’s discuss how we can work
                        together to make your vision a reality
                      </p>
                    </div>
                 
                    {data.length > 0 ? (
                    data.map(item => (

                    <ul className="ft-link ft-link-style-three">
                      <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i className="fa-light fa-phone" />
                          </div>
                          <div className="content">
                            <span>Call Us</span>
                            <a className="contact-here" href="tel:+4733378901">
                            {item.phone} || {item.secondary_phone}
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i className="fa-solid fa-envelope-open-text" />
                          </div>
                          <div className="content">
                            <span>E-mail</span>
                            <a
                              className="contact-here"
                              href="mailto:webmaster@example.com"
                            >
                              {item.email}
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i className="fa-regular fa-map-location-dot" />
                          </div>
                          <div className="content">
                            <span>Address</span>
                            <a className="contact-here" href="#">
                             {item.address}
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>

                    ))
                    ) : (
                      <p>No data available.</p>
                    )}
                 
                  </div>

                


                </div>
                <div className="col-lg-7">
                  <div className="contact-inner">
                    <div className="section-head section-head-one-side text-align-left">
                      <span className="title">GET IN TOUCH</span>
                      <p className="description">
                        At Punjab Travels, we’re more than just a service
                        provider. We’re your partner in success.Feel free to
                        adjust the content according to your brand's tone and
                        specific communication channels!
                      </p>
                    </div>
                    <div className="contact-form style-two">
                     {message && <h5 style={{ color:'green' , fontSize:'25px', padding:"5px 0" }} >{message}</h5>}

                      <div id="form-messages" />
                      <form id="contact-form" onSubmit={handleSubmit} >
                      <div className="contact-form-wrapper row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="input-field"
                              value={formData.name}
                              onChange={handleInputChange}
                              name='name'
                              type="text"
                              placeholder="Enter your name"
                              id="name"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="input-field"
                              value={formData.phone}
                              onChange={handleInputChange}
                              name='phone'
                              type="text"
                              placeholder="Enter your phone"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="input-field"
                              value={formData.email}
                              onChange={handleInputChange}
                              name='email'
                              placeholder="Enter your email"
                              type="email"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="input-field"
                              value={formData.subject}
                              onChange={handleInputChange}
                              name='subject'
                              placeholder="Enter your subject"
                              type="text"
                              id="subject"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              className="input-field"
                              value={formData.description}
                              onChange={handleInputChange}
                              name='description'
                              placeholder="Enter your description"
                              type="text"
                              id="description"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-submit-group">
                            <button
                              name="submit"
                              className="tmp-btn btn-primary"
                              type="submit"
                              id="submit"
                            >
                              Submit Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tmp contact area */}
          <div className="tmp-map-area tmp-sectiongapBottom">
            <div className="map-area-flotimg-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.0511041377367!2d-9.139282924071846!3d38.716635671763115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1933864fbefb29%3A0x9513490699f49b70!2sCentro%20Comercial%20Martim%20Moniz!5e0!3m2!1sen!2sbd!4v1729388157744!5m2!1sen!2sbd"
                width={600}
                height={650}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default ContactPage;
