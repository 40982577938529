import React, { useState , useEffect }  from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WOW from "wowjs";


function Team() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {

        function performSignIn() {
            let headers = new Headers();
            let url = "/api/aboutTeam";
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin', 'http://localhost:3000');
      
            fetch(url, {
              mode: 'cors',
              credentials: 'include',
              method: 'GET',
              cache: 'no-cache',
              headers: headers
            })
              .then(response => response.json())
              .then(json => console.log(json))
              .catch(error => console.log('Authorization failed: ' + error.message));
          }
          performSignIn()

             // Fetch data using axios
                axios.get('/backend/api/aboutTeam')

                .then(response => {
                
                setData(response.data); // Set data from API response
                setLoading(false);      // Set loading to false when data is fetched
                })
                .catch(err => {
                setError(err.message);  // Set error message if request fails
                setLoading(false);
                });

        // This disables continuous scanning for new elements
        const wow = new WOW.WOW({
          live: false, 
        });
        wow.init();


      }, []);


  return (
    <div>
        <>
            {/* Start team area  */}
            <div className="team-area tmp-section-gap bg-white">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-head">
                        <div className="section-sub-title center-title">
                        <img
                            src="assets/images/services/section-custom-menubar.png "
                            alt="Corporate services"
                        />
                        <span>OUR EXPERT TEAM</span>
                        </div>
                        <h2 className="section-title split-collab">Meet Our Expert Team</h2>
                    </div>
                    </div>
                </div>
                <div className="row g-5">

                    
                  {data.length > 0 ? (
                    data.map(item => (

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".1s">
                        <div className="single-team">
                            <Link to={'/TeamDetails'} className="thumbnail">
                            <img src={`https://punjabtravels.net/backend/${item.image}`} alt={item.name}  />
                            </Link>
                            <div className="content">
                            <div className="team-name content-with-bg">
                                <Link to={'/TeamDetails'} className="name-area">
                                <h6 className="name">{item.name}</h6>
                                </Link>
                                <div className="designation">{item.title}</div>
                            </div>
                            <div className="share-icon-style-one">
                                <ul className="social-icons social-icons-with-bg">
                                <li>
                                    <Link to={item.facebook}>
                                    <i className="fa-brands fa-facebook-f" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={item.linkedin}>
                                    <i className="fa-brands fa-linkedin-in" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={item.twiter}>
                                    <i className="fa-brands fa-twitter" />
                                    </Link>
                                </li>
                                </ul>
                                <i className="fa-sharp fa-solid fa-share-nodes" />
                            </div>
                            </div>
                        </div>
                    </div>

                    ))
                    ) : (
                    <p>No data available.</p>
                    )}

               
                </div>
                </div>
            </div>
            {/* End team area  */}
        </>
    </div>
  )
}

export default Team