import React from 'react'
import { Link } from 'react-router-dom';

function Nav(){
    return(
        <div>
            <div className="header-nav main-nav-one">
                <nav className=''>
                    <ul className="parent-nav">
                         <li >
                             <Link className="nav-link" to="/">
                                 HOME
                            </Link>
                        </li>
                        <li >
                             <Link className="nav-link" to="/FindTalent">
                                 FIND A TALENT
                            </Link>
             
                        </li>
                        <li>
                            <Link className="nav-link" to="/FindJob">
                            FIND A JOB
                            </Link>
                        </li>
                  
                        <li className="">
                            <Link className="nav-link" to="/About">
                            ABOUT US
                                {/* <span className="rolling-text"><div className="block"><span className="letter">B</span><span className="letter">L</span><span className="letter">O</span><span className="letter">G</span></div><div className="block"><span className="letter">B</span><span className="letter">L</span><span className="letter">O</span><span className="letter">G</span></div></span> */}
                            </Link>
                            {/* <ul className="submenu">
                                <li><Link to="/Blog">Blog</Link></li>
                                <li><Link to="/BlogColOne">Blog Col 1</Link></li>
                                <li><Link to="/BlogColTwo">Blog Col 2</Link></li>
                                <li><Link to="/BlogDetails">Blog Details</Link></li>
                                <li><Link to="/BlogDetailsTwo">Blog Details v2</Link></li>
                            </ul> */}
                        </li>
                        <li className="has-dropdown">
                            <Link className="nav-link" to="/OurThinking">
                            OUR THINKING
                                <span className="rolling-text"><div className="block"><span className="letter">P</span><span className="letter">A</span><span className="letter">G</span><span className="letter">E</span><span className="letter">S</span></div><div className="block"><span className="letter">P</span><span className="letter">A</span><span className="letter">G</span><span className="letter">E</span><span className="letter">S</span></div></span>
                            </Link>
                            <ul className="submenu">
                                <li><Link to="/Appoinment">Appoinment</Link></li>
                                <li><Link to="/Project">Project</Link></li>
                                <li><Link to="/ProjectMesonary">Project Mesonary</Link></li>
                                <li><Link to="/ProjectMesonaryThree">Project Mesonary v2</Link></li>
                                <li><Link to="/ProjectSlider">Project Slider</Link></li>
                                <li><Link to="/ProjectDetails">Project Details</Link></li>
                                <li><Link to="/ProjectDetailsTwo">Project Details v2</Link></li>
                                <li><Link to="/TeamOne">Team</Link></li>
                                <li><Link to="/TeamTwo">Team Two</Link></li>
                                <li><Link to="/TeamDetails">Team Details</Link></li>
                                <li><Link to="/Pricing">Pricing</Link></li>
                                <li><Link to="/FaqOne">Faq</Link></li>
                                <li><Link to="/Error">Error</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link className="nav-link" to="/Contact">
                                <span className="rolling-text"><div className="block"><span className="letter">C</span><span className="letter">O</span><span className="letter">N</span><span className="letter">T</span><span className="letter">A</span><span className="letter">C</span><span className="letter">T</span></div><div className="block"><span className="letter">C</span><span className="letter">O</span><span className="letter">N</span><span className="letter">T</span><span className="letter">A</span><span className="letter">C</span><span className="letter">T</span></div></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}


export default Nav