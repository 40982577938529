import React, { useState , useEffect }  from 'react';
import { Link } from 'react-router-dom';
import WOW from "wowjs";

const Header = ({ id }) => {

  const [isOpen, setIsOpen] = useState(false);

  const openVideo = () => {
    setIsOpen(true);
  };

  const closeVideo = () => {
    setIsOpen(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      closeVideo();
    }
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  
    return(
        <div id={id}>
            <div>
              <div className="about-area tmp-section-gap about-style-one">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="about-thumbnails">
                        <div className="thumbnail">
                          <img className='wow zoomIn' alt="corporate_business" src="assets/images/about/01.png"/>
                          <div className="image-two wow">
                            <img alt="corporate_business" src="assets/images/about/03.png" />
                          </div>
                          <div className="image-three wow">
                            <img alt="corporate_business" className="" src="assets/images/about/02.png"/>
                          </div>
                          <div className="square" />
                          <div className="flower">
                            <img
                              alt="Corporate_Business"
                              src="assets/images/about/flower.png"
                            />
                          </div>
                          <div className="product-share">
                            <div className="rating">
                              <div className="number">
                                4.8
                              </div>
                              <div className="stars-group">
                                <div className="star">
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star-half-stroke" />
                                </div>
                              </div>
                            </div>
                            <div className="profile-share">
                              <Link
                                className="avatar"
                                data-tooltip="Mark JOrdan"
                                to="#"
                                tabIndex="0">
                                <img
                                  alt="Business_Corporate"
                                  src="assets/images/about/4.png"
                                />
                              </Link>
                              <Link
                                className="avatar"
                                data-tooltip="Mark JOrdan"
                                to="#"
                                tabIndex="0"
                              >
                                <img
                                  alt="Business_Corporate"
                                  src="assets/images/about/5.png"
                                />
                              </Link>
                              <Link
                                className="avatar"
                                data-tooltip="Mark JOrdan"
                                to="#"
                                tabIndex="0"
                              >
                                <img
                                  alt="Business_Corporate"
                                  src="assets/images/about/6.png"
                                />
                              </Link>
                              <Link
                                className="avatar"
                                data-tooltip="Mark JOrdan"
                                to="#"
                                tabIndex="0"
                              >
                                <img
                                  alt="Business_Corporate"
                                  src="assets/images/about/7.png"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* video icon */}
                        <div className="vedio-icone" data-tmp-cursor="lg" data-tmp-cursor-text="Play Video">
                        <Link className="video-play-button play-video" href="#" onClick={(e) => {
                            e.preventDefault();
                            openVideo();
                            }}>
                            <span />
                        </Link>
                        
                        {isOpen && (
                            <div className="video-overlay open" onClick={closeVideo}>
                            <iframe
                                width="560"
                                height="515"
                                src="assets/images/video/intro-2.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                            <Link className="video-overlay-close" onClick={(e) => {
                                e.preventDefault();
                                closeVideo();
                                }}>×</Link>
                            </div>
                        )}

                        {/* Listen for the Escape key to close the video */}
                        {isOpen && <div onKeyUp={handleKeyUp} tabIndex="0"></div>}
                        </div>
                        {/* video icon */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="about-inner">
                        <div className="section-head text-align-left section-head-one-side">
                          <div className="section-sub-title wow slideInUp" data-wow-duration=".4s" data-wow-delay=".1s">
                            <img
                              alt="Corporate_service"
                              src="assets/images/services/section-custom-menubar.png "
                            />
                            <span className="subtitle">
                              ABOUT US
                            </span>
                          </div>
                          <h2 className="title split-collab wow slideInUp" data-wow-duration=".4s" data-wow-delay=".2s">
                            Find out more about our{' '}
                            <br />
                            {' '}business consulting
                          </h2>
                        </div>
                        <p
                          className="description wow slideInUp">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget ornare quam. Sed sit amet sem sollicitudin, condimentum diam sed, consequat tellus. Quisque ac odio eget ligula gravida efficitur. Nunc facilisis sagittis magna, non venenatis mauris luctus quis.
                        </p>
                        <div
                          className="single-progress-area progress-stye-one wow slideInUp" data-wow-duration=".4s" data-wow-delay=".2s">
                          <div className="progress-top">
                            <p className="name">
                              Consulting Service
                            </p>
                            <span className="parcent">
                              85%
                            </span>
                          </div>
                          <div className="progress">
                            <div
                              aria-valuemax="100"
                              aria-valuemin="0"
                              aria-valuenow="25"
                              className="progress-bar wow fadeInLeft bg--primary"
                              role="progressbar"
                              style={{
                                width: '85%'
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="single-progress-area progress-stye-one  wow slideInUp" data-wow-duration=".4s" data-wow-delay=".2s"
                        >
                          <div className="progress-top">
                            <p className="name">
                              Finance Consulting
                            </p>
                            <span className="parcent">
                              66%
                            </span>
                          </div>
                          <div className="progress">
                            <div
                              aria-valuemax="100"
                              aria-valuemin="0"
                              aria-valuenow="25"
                              className="progress-bar wow fadeInLeft bg--primary"
                              role="progressbar"
                              style={{
                                width: '66%'
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="thumbnail-with-title wow slideInUp" data-wow-duration=".4s" data-wow-delay=".2s"
                        >
                          <div className="thumbnail">
                            <img
                              alt=""
                              src="assets/images/about/about-with-icon.png"
                            />
                            <div className="icon">
                              <i className="fa-regular fa-dollar-sign" />
                            </div>
                          </div>
                          <div className="title">
                            Helping you run a more efficient                                & profitable business.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}


export default Header