import React from 'react';
import axios from 'axios';
import { useState } from 'react';

const StaffRequisitionForm = () => {


    const [formData, setFormData] = useState({
        name: '',vat: '',sector: '',address: '', city: '',zip : '', employer_country:'',
        contact_person_name:'',email :'',phone:'',
        country :'', nationalities:'', duties:'',requirements:'',workplace_location:'',occupation:'',
        position:'',work_experience:'',
        duration:'', expected_cost:'' ,tolerance_days:'' ,initiation_date:'' ,shift:'',
        transportation_option:'',overtime_option:'',accommodation_provided:'',flexible_date_option:''
      });
      const [message, setMessage] = useState(''); // State for messages



      function performSignIn() {
        let headers = new Headers();
        let url = "/backend/api/staffInfo/store";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
    
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'POST',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
       performSignIn()
    

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name,value);
        setFormData({
          ...formData,
          [name]: value, // Use name as the key to update the corresponding field
        });
      };


      // Handle form submission
    const handleSubmit = async (e) => {

      e.preventDefault();
      setMessage(''); // Clear previous message
  
      try {
        const response = await axios.post('https://punjabtravels.net/backend/api/staffInfo/store', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Show success message
        setMessage(response.data.message);
      } catch (error) {
        // Handle error and show error message
        if (error.response && error.response.data) {
          setMessage(error.response.data.message || 'An error occurred');
        } else {
          setMessage('An error occurred');
        }
      }
    };

  
      console.log(formData);




    return (
        <>
   <div className="col-lg-10">
        <div className="container">

                <form id="contact-form" onSubmit={handleSubmit} >


                {/* first stap start  */}
                <strong>1 of 4</strong>
                <br/><hr/>
                 <h3>01. Employer Identification</h3>
                 <hr/><br/>
                 {message && <h5 style={{ color:'green' , fontSize:'25px', padding:"5px 0" }} >{message}</h5>}
                <div className="row">

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="name">Name of Employer</label>
                            <input type="text" className="form-control"
                             value={formData.name}
                             onChange={handleInputChange}
                             name="name" 
                             id="name" 
                             placeholder="Enter Name of Employer"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="vat">Taxpayer or Business Identification Number:</label>
                            <input type="text" className="form-control"
                            value={formData.vat}
                            onChange={handleInputChange}
                            name="vat" id="vat" placeholder="VAT"  />
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="sector">Sector of Economic Activity:</label>
                            <input type="text" className="form-control"
                             value={formData.sector}
                             onChange={handleInputChange}
                            name="sector" id="sector" placeholder="Sector"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="address">Complete Address:</label>
                            <input type="text" className="form-control"
                             value={formData.address}
                             onChange={handleInputChange}
                            name="address" id="address" placeholder=" Address"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="city">City</label>
                            <input type="text" className="form-control"
                               value={formData.city}
                               onChange={handleInputChange}
                               name="city" id="city" placeholder="City"  />
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="zip">Zipcode:</label>
                            <input type="text" className="form-control"
                            value={formData.zip}
                            onChange={handleInputChange}
                            name="zip" id="zip" placeholder="ZIP"  />
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="employer_country">Country</label>
                            <input type="text" className="form-control"
                             value={formData.employer_country}
                             onChange={handleInputChange} name="employer_country" id="employer_country" placeholder="Country name"  />
                        </div>
                    </div>

                </div>
                {/* first stap end  */}


                {/* second step start  */}
                <hr/><br/>
                <strong>2 of 4</strong>
                <br/><hr/>
                 <h3>02. Contact Person</h3>
                 <hr/><br/>
                 {message && <h5 style={{ color:'green' , fontSize:'25px', padding:"5px 0" }} >{message}</h5>}
                <div className="row">

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="contact_person_name">Name:</label>
                            <input type="text" className="form-control"
                              value={formData.contact_person_name}
                              onChange={handleInputChange}
                              name="contact_person_name" id="contact_person_name" placeholder="Name"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="name">Email Address:</label>
                            <input type="email" className="form-control"
                            value={formData.email}
                            onChange={handleInputChange}
                             name="email" id="email" placeholder="Email"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="phone">Phone Number:</label>
                            <input type="text" className="form-control"
                            value={formData.phone}
                            onChange={handleInputChange}
                            name="phone" id="phone" placeholder="Phone Number"  />
                         
                        </div>
                    </div>

                </div>
                {/* second step end  */}


                {/* three step start  */}
                <hr/><br/>
                <strong>3 of 4</strong>
                <br/><hr/>
                 <h3>03. Description of the Occupation and Duties</h3>
                 <hr/><br/>
                 {message && <h5 style={{ color:'green' , fontSize:'25px', padding:"5px 0" }} >{message}</h5>}
                <div className="row">

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="occupation">Occupation:</label>
                            <input type="text" className="form-control"
                              value={formData.occupation}
                              onChange={handleInputChange} 
                              name="occupation" id="occupation" placeholder="Occupation"  />    
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="position">Number of Positions Available:</label>
                            <input type="number" className="form-control"
                            value={formData.position}
                            onChange={handleInputChange}  
                            name="position" id="position" placeholder="Position"  />
                        </div>
                    </div>


                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="workExperience">Work Experience Required:</label>
                        <div className="form-check">
                        <input
                            className="form-check-input"
                            value="yes"
                            checked={formData.work_experience === 'yes'}
                            onChange={handleInputChange}
                            type="radio"
                            name="work_experience"
                            id="workExperienceYes"
                        />
                        <label className="form-check-label" htmlFor="workExperienceYes">
                            YES
                        </label>
                        </div>

                        <div className="form-check">
                        <input
                            className="form-check-input"
                            value="no"
                            checked={formData.work_experience === 'no'}
                            onChange={handleInputChange}
                            type="radio"
                            name="work_experience"
                            id="workExperienceNo"
                        />
                        <label className="form-check-label" htmlFor="workExperienceNo">
                            NO
                        </label>
                        </div>
                    </div>
                    </div>


                    
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="duties">Duties</label>
                            <textarea type="text" className="form-control"
                            value={formData.duties}
                            onChange={handleInputChange}  
                            name="duties" id="duties" placeholder="Duties"  />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="requirements"> Requirements:</label>
                            <textarea type="text" className="form-control"
                            value={formData.requirements}
                            onChange={handleInputChange}  name="requirements" id="requirements" placeholder="language specific skills and characters etc"  />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="workplace_location">Workplace Location:</label>
                            <input type="text" className="form-control"
                             value={formData.workplace_location}
                             onChange={handleInputChange}
                             name="workplace_location" id="workplace_location" placeholder="nearest town and city"  />    
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="country">Country Name</label>
                            <input type="text" className="form-control"
                            value={formData.country}
                            onChange={handleInputChange}
                            name="country" id="country" placeholder="Country name"  />    
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="nationalities">Nationalities:</label>
                            <input type="text" className="form-control"
                               value={formData.nationalities}
                               onChange={handleInputChange} 
                               name="nationalities" id="nationalities" placeholder="Nationalities:"  />    
                        </div>
                    </div>

                </div>
                {/* three step end  */}



                 {/* four step start  */}
                <hr/><br/>
                <strong>4 of 4</strong>
                <br/><hr/>
                 <h3>04. Terms and Conditions of Staffing Agreement</h3>
                 <hr/><br/>
                 {message && <h5 style={{ color:'green' , fontSize:'25px', padding:"5px 0" }} >{message}</h5>}
                <div className="row">

                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="duration">Duration:</label>
                        <select
                        className="form-select form-select-lg p-4"
                        style={{ fontSize: '16px' }}
                        value={formData.duration}
                        onChange={handleInputChange}
                        id="duration"
                        name="duration"
                        aria-label=".form-select-lg example"
                        >
                        <option value="Less than 3 months">Less than 3 months</option>
                        <option value="Between 3 and 6 months">Between 3 and 6 months</option>
                        <option value="More than 6 months and less than 12 months">More than 6 months and less than 12 months</option>
                        <option value="12 months and more">12 months and more</option>
                        </select>
                    </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="initiation_date">Initiation Date (Expected)::</label>
                            <input type="date" className="form-control"
                             value={formData.initiation_date}
                             onChange={handleInputChange} 
                             name="initiation_date" id="initiation_date" placeholder="dd/mm/yyyy"  />
                        </div>
                    </div>

                    <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="tolerance_days">Tolerance (days)</label>
                      <input type="number" className="form-control" 
                       value={formData.tolerance_days}
                       onChange={handleInputChange} 
                       name="tolerance_days" id="tolerance_days" placeholder="Enter number of days" />
                    </div>
                  </div>


                     <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="transportation">Transportation Provided:</label>
                        <div className="row">
                        <div className="col-lg-4">
                            <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="Free of charge"
                                checked={formData.transportation_option === 'Free of charge'}
                                onChange={handleInputChange}
                                name="transportation_option"
                                id="flexRadioFree"
                            />
                            <label className="form-check-label" htmlFor="flexRadioFree">
                                Free of charge
                            </label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="For a fee"
                                checked={formData.transportation_option === 'For a fee'}
                                onChange={handleInputChange}
                                name="transportation_option"
                                id="flexRadioFee"
                            />
                            <label className="form-check-label" htmlFor="flexRadioFee">
                                For a fee
                            </label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="Not sure"
                                checked={formData.transportation_option === 'Not sure'}
                                onChange={handleInputChange}
                                name="transportation_option"
                                id="flexRadioNotSure"
                            />
                            <label className="form-check-label" htmlFor="flexRadioNotSure">
                                Not sure
                            </label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="Not applicable"
                                checked={formData.transportation_option === 'Not applicable'}
                                onChange={handleInputChange}
                                name="transportation_option"
                                id="flexRadioNotApplicable"
                            />
                            <label className="form-check-label" htmlFor="flexRadioNotApplicable">
                                Not applicable
                            </label>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>


                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="shift">Shift:</label>
                        <select
                        className="form-select form-select-lg p-4"
                        style={{ fontSize: '16px', height: 'auto' }} // Adjust height if needed
                        value={formData.shift}
                        onChange={handleInputChange}
                        id="shift"
                        name="shift" 
                        aria-label=".form-select-lg example"
                        >
                        <option value="Day">Day</option>
                        <option value="Night">Night</option>
                        <option value="Rotational">Rotational</option>
                        <option value="Mixed">Mixed</option>
                        <option value="Variable">Variable</option>
                        </select>
                    </div>
                    </div>


                      <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="overtime">Overtime Required:</label>
                        <div className="row">

                          <div className="col-lg-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio"
                                value="yes"
                                onChange={handleInputChange}
                                checked={formData.overtime_option === 'yes'}
                                name="overtime_option" id="overtimeYes" />
                              <label className="form-check-label" htmlFor="overtimeYes">
                                YES
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio"
                              value="no"
                              checked={formData.overtime_option === 'no'}
                              onChange={handleInputChange} 
                              name="overtime_option" id="overtimeNo" />
                              <label className="form-check-label" htmlFor="overtimeNo">
                                NO
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio" 
                              value="Not sure"
                              onChange={handleInputChange} 
                              checked={formData.overtime_option === 'Not sure'}
                              name="overtime_option" id="overtimeNotSure" />
                              <label className="form-check-label" htmlFor="overtimeNotSure">
                                Not sure
                              </label>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="flexibleDate">Flexible Date:</label>
                        <div className="row">

                          <div className="col-lg-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio"
                                value="yes"
                                onChange={handleInputChange}
                                checked={formData.flexible_date_option === 'yes'}
                                name="flexible_date_option" id="flexibleDateYes" />
                              <label className="form-check-label" htmlFor="flexibleDateYes">
                                YES
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio"
                               value="no"
                               checked={formData.flexible_date_option === 'no'}
                               onChange={handleInputChange}
                               name="flexible_date_option" id="flexibleDateNo" />
                              <label className="form-check-label" htmlFor="flexibleDateNo">
                                NO
                              </label>
                            </div>
                          </div>

                        </div>
                      </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="accommodationProvided">Accommodation Provided:</label>
                            <div className="row">

                            <div className="col-lg-3">
                                <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Free of charge"
                                     checked={formData.accommodation_provided === 'Free of charge'}
                                    onChange={handleInputChange}
                                    name="accommodation_provided"
                                    id="accommodationFree"
                                />
                                <label className="form-check-label" htmlFor="accommodationFree">
                                    Free of charge
                                </label>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="For a fee"
                                    checked={formData.accommodation_provided === 'For a fee'}
                                    onChange={handleInputChange}
                                    name="accommodation_provided"
                                    id="accommodationFee"
                                />
                                <label className="form-check-label" htmlFor="accommodationFee">
                                    For a fee
                                </label>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Not sure"
                                    checked={formData.accommodation_provided === 'Not sure'}
                                    onChange={handleInputChange}
                                    name="accommodation_provided"
                                    id="accommodationNotSure"
                                />
                                <label className="form-check-label" htmlFor="accommodationNotSure">
                                    Not sure
                                </label>
                                </div>
                            </div>

                            </div>
                        </div>
                        </div>


                      <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="expectedCost">Expected Cost of Staffing / Per Hour</label>
                        <input type="number" className="form-control" 
                        value={formData.expected_cost}
                        onChange={handleInputChange}
                        name="expected_cost" id="expectedCost" placeholder="Enter cost per hour" />
                      </div>
                    </div>

                      

                </div>
                {/* three step end  */}


                <button type="submit" style={{fontSize:'20px'}} className="btn-lg p-3  btn-primary">Request</button>
                </form>
                
        </div>
    </div>

            
        </>
    );
};

export default StaffRequisitionForm;