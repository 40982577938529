import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { ReactComponent as UKFlag } from "../../src/language/en.svg";
import { ReactComponent as PTFlag } from "../../src/language/pt.svg";
import { ReactComponent as SVFlag } from "../../src/language/sv.svg";
import { ReactComponent as DEFlag } from "../../src/language/de.svg";

const HeaderNavNew = () => {
  const languageOptions = [
    { value: "en", label: "EN", googleCode: "en", flag: <UKFlag width={20} height={15} /> },
    { value: "pt", label: "PT", googleCode: "pt", flag: <PTFlag width={20} height={15} /> },
    { value: "sv", label: "SW", googleCode: "sv", flag: <SVFlag width={20} height={15} /> },
    { value: "de", label: "GE", googleCode: "de", flag: <DEFlag width={20} height={15} /> },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);

    // Trigger Google Translate language change
    const googleTranslateDropdown = document.querySelector(".goog-te-combo");

    if (googleTranslateDropdown) {
      googleTranslateDropdown.value = selectedOption.googleCode;
      googleTranslateDropdown.dispatchEvent(new Event("change"));
    } else {
      console.error("Google Translate dropdown not found.");
    }
  };

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      if (!document.querySelector("script[src*='translate.google.com']")) {
        const script = document.createElement('script');
        script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;
        document.body.appendChild(script);
      }
    };

    // Initialize Google Translate
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en', // Your default language
          includedLanguages: 'en,pt,sv,de', // Languages you want to include
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
        },
        'google_translate_element'
      );
    };

    // Load Google Translate script only once on component mount
    addGoogleTranslateScript();
  }, []);

  return (
    <div>
      <div className="header-nav main-nav-one">
        <nav>
          <ul className="parent-nav">
            <li>
              <Link className="nav-link" to="/">
                HOME
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/FindTalent">
                FIND A TALENT
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/FindJob">
                FIND A JOB
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/About">
                ABOUT US
              </Link>
            </li>
            <li className="has-dropdown">
            <Link className="nav-link" to="">
              OUR THINKING
              <span className="rolling-text"></span>
            </Link>
            <ul className="submenu">
              <li><Link to="/OurThinking">Blogs</Link></li>
              <li><Link to="/StaffRequisition">Staff Requisition</Link></li>
          </ul>
            </li>
            <li>
              <Link className="nav-link" to="/Contact">
                <span className="rolling-text">
                  <div className="block">
                    <span className="letter">C</span>
                    <span className="letter">O</span>
                    <span className="letter">N</span>
                    <span className="letter">T</span>
                    <span className="letter">A</span>
                    <span className="letter">C</span>
                    <span className="letter">T</span>
                  </div>
                  <div className="block">
                    <span className="letter">C</span>
                    <span className="letter">O</span>
                    <span className="letter">N</span>
                    <span className="letter">T</span>
                    <span className="letter">A</span>
                    <span className="letter">C</span>
                    <span className="letter">T</span>
                  </div>
                </span>
              </Link>
            </li>

            {/* Custom Language Dropdown */}
            <li>
              <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                options={languageOptions}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.flag} {/* Display the flag */}
                    <span style={{ marginLeft: 10 }}>{e.label}</span> {/* Display the label */}
                  </div>
                )}
              />
            </li>
          </ul>
        </nav>
      </div>

      {/* Hidden Google Translate Element */}
      <div id="google_translate_element" style={{ display: "none" }}></div>
    </div>
  );
};

export default HeaderNavNew;
