import React from 'react'
import HeaderInner from '../header/HeaderInner';
import ProjectDetails from './breadcrumb/ProjectDetails';
import Footer from '../elements/Footer';
import HeaderSix from '../header/HeaderSix';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';



function ProjectDetailsNew() {

    const {id} = useParams();
    console.log(id);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
  
      function performSignIn() {
        let headers = new Headers();
        let url =`/api/jobPost/${id}`;
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
  
  
      performSignIn()
  
      // Fetch data using axios
      axios.get(`/backend/api/jobPost/${id}`)
  
        .then(response => {
          setData(response.data); // Set data from API response
          setLoading(false);      // Set loading to false when data is fetched
        })
        .catch(err => {
          setError(err.message);  // Set error message if request fails
          setLoading(false);
        });


    }, [id]);
  
  
    if (loading) {
      return <p>loading data || Punjab travels & money transfer...</p>;
    }



  return (
    <div>
        {/* <HeaderInner/> */}
        <HeaderSix/>
        <>
        <ProjectDetails/>


        {/* Start Projects Details area  */}
        <div className="projects-details-area tmp-section-gap">
            <div className="container">
            <div className="row">

               

                <div className="col-lg-12">
                <div className="projects-details-inner">
                    <div className="thumbnail position-relative">
                    <img
                        src="/assets/images/projects-details/projects-details.jpg"
                        alt="projects-details"
                    />
                    </div>
                    <div className="clients-projects-details">
                    <div className="clients-details-inner">
                        <h3 className="title text-center">JOB POST DETAILS</h3>
                        <div className="single-projects-details">
                        <div className="info">
                            <div className="icon">
                            <i className="fa-solid fa-user" />
                            </div>
                            <div className="content">
                            <h4 className="subtitle">Contact:</h4>
                            <h5 className="category">{data.Email}</h5>
                            </div>
                        </div>
                        <div className="info">
                            <div className="icon">
                            <i className="fa-sharp fa-light fa-layer-group" />
                            </div>
                            <div className="content">
                            <h4 className="subtitle">Category:</h4>
                            <h5 className="category">{data.category}</h5>
                            </div>
                        </div>
                        <div className="info">
                            <div className="icon">
                            <i className="fa-light fa-calendar-days" />
                            </div>
                            <div className="content">
                            <h4 className="subtitle">Last Date:</h4>
                            <h5 className="category">{data.closing_at}</h5>
                            </div>
                        </div>
                        <div className="info">
                            <div className="icon">
                            <i className="fa-regular fa-map-location" />
                            </div>
                            <div className="content">
                            <h4 className="subtitle">Address:</h4>
                            <h5 className="category">{data.location}</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                  
                    <div className="projects-details-wrapper">
                        <h3 className="title">{data.title}</h3>
                        <p className="description">
                         {data.description}
                        </p>
                        <div className="row">
                        <div className="col-lg-6">
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Company: </strong>{data.company_name}
                            </p>
                            </div>
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Experience : </strong>{data.experience_level}
                            </p>
                            </div>
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Shift : </strong>{data.job_time}
                            </p>
                            </div>
                         
                        </div>
                        <div className="col-lg-6">
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Salary: </strong>{data.salary}
                            </p>
                            </div>
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Phone: </strong>{data.phone}
                            </p>
                            </div>
                            <div className="challenge-details">
                            <div className="icon">
                                <i className="fa-solid fa-check" />
                            </div>
                            <p className="description">
                            <strong>Gender : </strong>{data.gender}
                            </p>
                            </div>

                        </div>
                        </div>
                        <p className="description mt--30">
                          {data.description}
                        </p>
                        <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="thumbnail">
                            <img src={`https://punjabtravels.net/backend/${data.image}`} alt={data.title} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="thumbnail">
                            <img src={`https://punjabtravels.net/backend/${data.image}`} alt={data.title} />
                            </div>
                        </div>
                        </div>
                   
                    </div>
                    </div>
                </div>
                </div>


            </div>
            </div>
        </div>
        {/* Start Projects Details area  */}

            <Footer/>
        </>
        

    </div>
  )
}

export default ProjectDetailsNew