import React, { useEffect,useState }  from 'react';
import WOW from "wowjs";
import { Link } from 'react-router-dom';
import axios from 'axios';


function ServiceOne(){

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    function performSignIn() {
      let headers = new Headers();
      let url = "/api/serviceSection";
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:3000');

      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: headers
      })
        .then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }
    performSignIn()

       // Fetch data using axios
          axios.get('/backend/api/serviceSection')

          .then(response => {
          
          setData(response.data); // Set data from API response
          setLoading(false);      // Set loading to false when data is fetched
          })
          .catch(err => {
          setError(err.message);  // Set error message if request fails
          setLoading(false);
          });

    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  
  
    if (loading) {
      return <p>loading data || Punjab travels & money transfer...</p>;
    }

    return(
      <div>
          <div>
            <div className="tmp-services-area services-style--1 background-image-services bg_image tmp-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head">
                      <div className="section-sub-title center-title">
                        <img alt="Business_Consulting_services" src="assets/images/services/section-custom-menubar.png " />
                        <span>
                          OUR SERVICES
                        </span>
                      </div>
                      <h2 className="title split-collab">
                        Our Provided Services
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="row g-5">

                {data.length > 0 ? (
                    data.map(item => (

                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".1s">
                    <div className="single-services service-style-one">
                      <div className="services-inner  with-shadow card-bg-color">
                        <div className="thumbnail">
                          <img src={`https://punjabtravels.net/backend/${item.image}`} alt={item.title}
                          />
                        </div>
                        <div className="services-content">
                          <Link to={"/ServiceDetails"}>
                            <h5 className="title">
                             {item.title}
                            </h5>
                          </Link>
                          <p className="desctiption">
                            {item.description}
                          </p>
                       
                        </div>
                       
                      </div>
                    </div>
                  </div>

                  ))
                  ) : (
                  <p>No data available.</p>
                  )}


                
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="services-bottom">
                      <div className="services-bottom-inner">
                        <p className="description">
                          Our success as consultants will depend on the essential rightness of the advice.
                        </p>
                        <p className="description">
                          Want to learn more About us?
                          <button className="btn-simple">
                            <Link to="/About"> Click here </Link>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
}


export default ServiceOne





