import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";

import axios from "axios";
import { useEffect, useState } from "react";

function ServiceNewStaff() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    function performSignIn() {
      let headers = new Headers();
      let url = "/api/categories/limit";
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:3000');

      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: headers
      })
        .then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }

    performSignIn()

    // Fetch data using axios
    axios.get('/backend/api/categories/limit')

      .then(response => {

        setData(response.data); // Set data from API response
        setLoading(false);      // Set loading to false when data is fetched
      })
      .catch(err => {
        setError(err.message);  // Set error message if request fails
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>loading data || Punjab travels & money transfer...</p>;
  }

  return (
    <div>
      {/* service area start */}
      <div className="tmp-service-area tmp-section-gapTop">
        <div className="container  text-center">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-head">
                <div className="section-sub-title center-title">
                  <img
                    src="assets/images/services/service-icon-1.png "
                    alt="Business_Consulting_services"
                    height="30px"
                    width="30px"
                  />
                  <span>Hiring and Providing Manpower</span>
                </div>
                <h2 className="title split-collab">
                  Staff Requisition Services
                </h2>
                <p style={{padding:'5px 20px'}}>
                  At Grow Strive, we simplify talent acquisition with tailored
                  staffing solutions, from role definition to precise candidate
                  selection. Leveraging industry expertise, we ensure the
                  perfect match to fuel your business growth.
                </p>
              </div>
            </div>
          </div>
          
          <div className="container  d-flex justify-content-center">
          <div className="content-section ">
            <div className="row gap-0  justify-content-center">

              {data.length > 0 ? (
              data.map(item => (


                <div className="col-md-4 ">
                  <div className="content-staff-service">
                    <div className="header">
                    <i className={`fa ${item.icon}`} aria-hidden="true"></i>          
                      <h2>{item.name}</h2>
                    </div>
                    <div className="body">
                      At Grow Strive, we simplify talent acquisition with tailored staffing solutions, from role definition to precise candidate selection. Leveraging industry expertise.
                    </div>
                  </div>
                </div>

              ))
              ) : (
                  <p>No data available.</p>
              )}

           

            </div>
          </div>
         </div>

          <button className="content-staff-service-btn"><Link to="/StaffRequisition">Apply To Hire Manpower</Link> </button>
         

        </div>
      </div>

      {/* service area end */}
    </div>
  );
}

export default ServiceNewStaff;
